<style>
  .wave {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 0% 100%;
    display: inline-block;
    position: relative;
  }

  .wave > svg {
    position: absolute;
    top: -26px;
    left: -27px;
  }

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(20deg);
    }
    20% {
      transform: rotate(-2deg);
    }
    30% {
      transform: rotate(20deg);
    }
    40% {
      transform: rotate(1deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
</style>

<span class="wave">
  <svg
    width="40"
    height="40"
    viewBox="0 0 103 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      transform="scale(1,1) translate(0, 0)"
      d="M47.3407 46.0707L47.342 46.0694L47.3432 46.068C47.5142 45.8839 47.7346
      45.5533 48.0034 45.0838C48.2734 44.6123 48.596 43.9942 48.9707
      43.2299C49.7201 41.7013 50.6802 39.584 51.8493 36.8771C54.1874 31.4632
      57.3628 23.6872 61.3629 13.5369C63.0127 9.3874 64.4565 6.70502 65.9779
      5.19597C66.7365 4.4435 67.5117 3.98564 68.3392 3.78143C69.167 3.57715
      70.0554 3.62469 71.0434 3.8964L71.0447 3.89674C72.1903 4.19559 73.0021
      4.56914 73.482 5.29126C73.9631 6.0152 74.1262 7.11285 73.9082
      8.90037C73.4722 12.4753 71.5267 18.7135 67.7751 29.9684L67.775
      29.9686C65.8247 35.8696 64.1242 41.3709 62.9488 45.4976C62.3611 47.5607
      61.9043 49.2813 61.6132 50.5368C61.4676 51.1643 61.3631 51.6772 61.3043
      52.0594C61.275 52.2503 61.2567 52.4105 61.2508 52.537C61.245 52.6588
      61.2497 52.764 61.2763 52.8351L61.2771 52.8372L61.278 52.8394C61.4428
      53.2238 61.8457 53.5382 62.3466 53.7308C62.8497 53.9243 63.4667 54.0014
      64.0837 53.8991C64.5651 53.8447 65.0717 53.5583 65.6665 52.9761C66.263
      52.3923 66.9581 51.5011 67.8156 50.218C69.5309 47.6513 71.9079 43.4975
      75.4576 37.0482L75.4578 37.0478C79.7094 29.2447 82.0056 25.1272 83.6133
      22.9214C84.4163 21.8195 85.0413 21.2039 85.6446 20.8437C86.2456 20.4849
      86.8339 20.3741 87.579 20.2996C88.825 20.2 89.6784 20.2521 90.379
      20.5662C91.078 20.8795 91.64 21.4604 92.2861 22.4545L92.2867
      22.4554C92.6858 23.0541 92.9618 23.5255 93.0506 24.153C93.1398 24.783
      93.0416 25.5819 92.6618 26.8334C91.9015 29.3384 90.0302 33.6057 86.3786
      41.8592C84.2783 46.5599 82.3779 50.9859 81.0024 54.337C80.3147 56.0125
      79.758 57.4199 79.3731 58.459C79.1806 58.9784 79.0307 59.4067 78.9288
      59.7309C78.8779 59.893 78.8386 60.0301 78.812 60.1401C78.7859 60.2477
      78.7699 60.3371 78.7699 60.4C78.7699 61.4099 79.5661 62.1626 80.5516
      62.4826C81.5394 62.8033 82.7519 62.7032 83.6335 61.9772L83.6372
      61.9741L83.6407 61.9707C83.8222 61.7892 84.1015 61.4329 84.4466
      60.9503C84.7931 60.4659 85.2096 59.8489 85.6664 59.1417C86.5801 57.7274
      87.6562 55.9505 88.6574 54.1485C90.6556 50.5516 93.1533 46.3554 94.1533
      44.7553C95.684 42.5843 97.845 41.8076 99.6115 42.1853C101.374 42.562
      102.77 44.0925 102.77 46.6C102.77 46.7432 102.702 47.0205 102.56
      47.4306C102.421 47.8356 102.215 48.357 101.949 48.9809C101.418 50.2285
      100.651 51.8812 99.7096 53.8236C97.8275 57.7081 95.25 62.7476 92.469
      68.0158C86.9065 78.5534 80.5337 89.9991 77.2866 94.9448C73.4931 100.635
      69.3544 104.697 64.4974 107.325C59.6405 109.952 54.058 111.15 47.3707
      111.1L47.3705 111.1C37.7867 111.05 29.859 108.679 23.755 104.095C17.6514
      99.5111 13.3622 92.7073 11.0668 83.7751L11.0667 83.7747C9.86673 79.1748
      6.96657 68.0742 4.46638 58.9735C1.81549 49.3203 0.443666 43.7554 0.157106
      40.3167C0.0139419 38.5987 0.142995 37.4234 0.510732 36.5383C0.877052
      35.6567 1.48499 35.0518 2.32512 34.4834C3.44155 33.7641 4.43999 33.3881
      5.40681 33.4093C6.37164 33.4305 7.32175 33.8478 8.34118 34.7499C9.36202
      35.6532 10.4467 37.0377 11.6777 38.9816C12.9081 40.9247 14.2814 43.4215
      15.8809 46.5455L15.8811 46.5458C19.0797 52.7432 21.0086 56.2764 22.3268
      58.0717C22.9833 58.9657 23.5026 59.4498 23.9684 59.6028C24.2065 59.681
      24.4289 59.6723 24.642 59.5913C24.8521 59.5114 25.0482 59.3632 25.2407
      59.1707C25.3184 59.093 25.3998 58.9416 25.4847 58.7442C25.5717 58.5418
      25.6685 58.2761 25.7728 57.956C25.9817 57.3154 26.2229 56.4504 26.4795
      55.4242C26.9926 53.3716 27.5681 50.6693 28.0684 47.8173L28.0686
      47.8166C30.1183 35.6682 32.3428 24.5955 34.3297 16.3359C35.3231 12.2058
      36.2568 8.78061 37.079 6.27649C37.4902 5.02424 37.8729 4.00431 38.2205
      3.24247C38.3943 2.86153 38.5587 2.54673 38.7126 2.30035C38.8673 2.05277
      39.0081 1.87977 39.1333 1.77737L39.1336 1.77707C39.7099 1.30101 40.1489
      0.942613 40.5337 0.693272C40.9168 0.445018 41.2391 0.30974 41.5802
      0.274449C41.9222 0.239061 42.2939 0.303038 42.7775 0.472471C43.2619
      0.642161 43.8506 0.914598 44.6258 1.28971C45.2092 1.58154 45.8235 2.12049
      46.3301 2.73838C46.8371 3.3566 47.2275 4.04311 47.3728 4.62353C47.4073
      4.77405 47.4262 5.05392 47.4276 5.45774C47.4289 5.85945 47.413 6.37539
      47.381 6.99166C47.3171 8.22405 47.1891 9.85373 47.0079 11.7655C46.6455
      15.589 46.0707 20.5376 45.3709 25.6865C44.2211 33.9847 43.6204 38.7145
      43.4701 41.5321C43.32 44.3456 43.6157 45.2871 44.2992 45.9707C44.625
      46.2965 44.8991 46.5457 45.1462 46.7152C45.3937 46.8851 45.6217 46.9804
      45.8544 46.9874C46.088 46.9945 46.3113 46.9123 46.5482 46.7571C46.7838
      46.6027 47.0399 46.3715 47.3407 46.0707Z"
      fill="white"
      stroke="black"
      stroke-width="2" />
  </svg>
</span>
